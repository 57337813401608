import { forwardRef, memo, useState, useCallback } from 'react';
import {
  DetailsView,
  FileManagerComponent,
  NavigationPane,
  Toolbar,
  Inject,
  FileManagerModel,
} from '@syncfusion/ej2-react-filemanager';
import { Dialog, Title, Content } from 'modules/shared/components/Dialog';
import Storage from 'modules/tokens-storage';
import { getApiBaseUrl, noop } from 'modules/shared/utils';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { useToggle } from 'modules/shared/hooks/base';
import { useUploaderHooks } from 'modules/shared/hooks/hdfs-upload/useUploaderHooks';
import JsonPreview from './preview/JsonPreview';
import { useStyles } from './styles';
import XlsPreview from './preview/XlsPreview';

const hostUrl = getApiBaseUrl();
const UPLOAD_MODAL_ID = 'upload-modal';
const MaxUploadSize = 3.5 * 1024 * 1024 * 1024; // 3.5Gb

const PREVIEW_TYPES = {
  XLSX: 'xsls',
  JSON: 'json',
};

const EXTENSION_TO_TYPE = {
  '.xlsx': PREVIEW_TYPES.XLSX,
  '.xls': PREVIEW_TYPES.XLSX,
  '.csv': PREVIEW_TYPES.XLSX,
  '.json': PREVIEW_TYPES.JSON,
};

const PREVIEW_COMPONENTS = {
  [PREVIEW_TYPES.XLSX]: XlsPreview,
  [PREVIEW_TYPES.JSON]: JsonPreview,
};

type FileDetails = {
  name: string;
  filterPath: string;
  type: string;
};

type ToolbarItem = {
  name: string;
  icon: string;
  disabled?: boolean;
};

type Props = {
  id: string;
  path: string;
  beforeSendBody: Record<string, any>;
  onBeforeSend?: () => void;
  onSuccess?: () => void;
  customToolbarItems?: ToolbarItem[];
  onToolbarClick?: FileManagerModel['toolbarClick'];
  onFileSelect?: FileManagerModel['fileSelect'];
  onMenuOpen?: FileManagerModel['menuOpen'];
  onMenuClick?: FileManagerModel['menuClick'];
  onBeforeDownload?: FileManagerModel['beforeDownload'];

  allowUpload?: boolean;
  allowDownload?: boolean;
  allowRename?: boolean;
  allowFolderCreate?: boolean;
  allowFileOpen?: boolean;
  allowDelete?: boolean;
  customFileContextMenuActions?: string[];
  customFolderContextMenuActions?: string[];

  customUploadProps?: {
    connection: string;
    projectId: string;
    settings: {
      saveUrl: string;
      removeUrl: string;
      chunkSize: number; // 5 MB
      retryCount: number;
    };
  };
};

const createToolbarItems = (items: ToolbarItem[]) => (args) => {
  args.items?.forEach((item) => {
    const tbItem = items.find((i) => i.name === item.text);

    if (tbItem) {
      item.prefixIcon = `e-icons ${tbItem.icon}`;

      item.disabled = tbItem.disabled || false;
    }
  });
};

const INIT_PREVIEW_STATE = {
  isOpen: false,
  type: '',
  filePath: '',
  fileName: '',
};

const FileManager = forwardRef<FileManagerComponent, Props>(
  (
    {
      id,
      beforeSendBody,
      customToolbarItems = [],
      path,
      onBeforeSend = noop,
      onToolbarClick,
      onFileSelect,
      onMenuClick,
      onMenuOpen,
      allowFileOpen = false,
      allowUpload = false,
      allowDownload = false,
      allowRename = false,
      allowFolderCreate = false,
      allowDelete = false,
      onBeforeDownload,
      customFolderContextMenuActions = [],
      customFileContextMenuActions = [],
      customUploadProps,
      onSuccess,
    }: Props,
    fileManagerRef,
  ) => {
    const classes = useStyles();
    const previewModalId = `${id}-preview-modal`;
    const [preview, setPreview] = useState(INIT_PREVIEW_STATE);

    const handlePreviewClose = useCallback(() => {
      setPreview(INIT_PREVIEW_STATE);
    }, []);

    const PreviewComponent = PREVIEW_COMPONENTS[preview.type];

    const [isOpenUploadModal, { activate: openUploadModel, deactivate: closeUploadModal }] = useToggle();

    const {
      handleCanceling,
      handleClearing,
      handleClose,
      handleRemoving,
      handleSuccess,
      handleUploading,
      instance: fileUploaderRef,
      handleBeforeUpload,
    } = useUploaderHooks({
      getCustomData: () => {
        return {
          // @ts-ignore
          uploadPath: fileManagerRef?.current.pathNames.slice(1).join('/') || '/',
        };
      },
      connection: customUploadProps?.connection,
      projectId: customUploadProps?.projectId,
      onSuccess: () => {
        // handleUploadModalClose();
        // @ts-ignore
        fileManagerRef?.current?.refreshFiles();
      },
      onClose: () => {
        fileUploaderRef.current?.clearAll();
        closeUploadModal();
      },
    });

    const uploadComponent = (
      <UploaderComponent
        id="upload"
        type="file"
        ref={(scope) => {
          fileUploaderRef.current = scope;
        }}
        selected={() => {
          openUploadModel();
        }}
        beforeUpload={handleBeforeUpload}
        uploading={handleUploading}
        asyncSettings={customUploadProps?.settings}
        removing={handleRemoving}
        chunkUploading={handleUploading}
        canceling={handleCanceling}
        autoUpload={false}
        maxFileSize={MaxUploadSize}
        success={handleSuccess}
        clearing={handleClearing}
        enabled={allowUpload}
      />
    );

    return (
      <>
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <FileManagerComponent
              id={id}
              ref={fileManagerRef}
              allowDragAndDrop={false}
              allowMultiSelection
              height="100%"
              success={() => {
                // @ts-ignore
                if (fileManagerRef.current.uploadObj.dropArea) {
                  // @ts-ignore
                  fileUploaderRef.current.dropArea = fileManagerRef.current.uploadObj.dropArea;
                  // @ts-ignore
                  fileManagerRef.current.uploadObj.dropArea = null;
                }
                onSuccess?.();
              }}
              menuOpen={onMenuOpen}
              toolbarCreate={createToolbarItems(customToolbarItems)}
              beforeSend={(args) => {
                onBeforeSend();
                let body = JSON.parse(args.ajaxSettings.data);

                body = {
                  ...body,
                  ...beforeSendBody,
                };

                args.ajaxSettings.data = JSON.stringify(body);

                args.ajaxSettings.beforeSend = (sendArgs) => {
                  sendArgs.httpRequest.setRequestHeader('Authorization', `Bearer ${Storage.getInmemoryIdToken()}`);
                };
              }}
              view="Details"
              ajaxSettings={{
                url: `${hostUrl}${path}`,
              }}
              toolbarSettings={{
                items: [
                  ...(allowFolderCreate ? ['NewFolder'] : []),
                  ...(allowDownload ? ['Download'] : []),
                  ...(allowRename ? ['Rename'] : []),
                  ...(allowDelete ? ['Delete'] : []),
                  ...(allowUpload ? ['Upload'] : []),
                  'SortBy',
                  'Refresh',
                  'Selection',
                  'View',
                  ...customToolbarItems.map((i) => i.name),
                ],
              }}
              toolbarClick={onToolbarClick}
              fileSelect={onFileSelect}
              contextMenuSettings={{
                file: [
                  ...(allowFileOpen ? ['Open'] : []),
                  ...(allowDownload ? ['Download'] : []),
                  ...(allowRename ? ['Rename'] : []),
                  ...(allowDelete ? ['Delete'] : []),
                  ...customFileContextMenuActions,
                ],
                folder: [
                  'Open',
                  ...(allowRename ? ['Rename'] : []),
                  ...(allowDelete ? ['Delete'] : []),
                  ...customFolderContextMenuActions,
                ],
                layout: [
                  'SortBy',
                  'View',
                  'Refresh',
                  ...(allowFolderCreate ? ['NewFolder'] : []),
                  ...(allowUpload ? ['Upload'] : []),
                  'SelectAll',
                ],
              }}
              menuClick={onMenuClick}
              fileOpen={
                allowFileOpen
                  ? (args) => {
                      const fileName: string = ((args?.fileDetails || {}) as FileDetails).name;
                      const filePath: string = ((args?.fileDetails || {}) as FileDetails).filterPath.replace(
                        /\\/g,
                        '/',
                      );
                      const fileType: string = ((args?.fileDetails || {}) as FileDetails).type;

                      const type = EXTENSION_TO_TYPE[fileType];

                      if (type) {
                        setPreview({
                          isOpen: true,
                          type,
                          filePath,
                          fileName,
                        });
                      }
                    }
                  : noop
              }
              beforeDownload={onBeforeDownload}
            >
              <Inject services={[NavigationPane, DetailsView, Toolbar]} />
            </FileManagerComponent>
          </div>
        </div>
        <Dialog
          classes={{
            paper: classes.uploadDialog,
          }}
          fullWidth
          onClose={handleClose}
          aria-labelledby={UPLOAD_MODAL_ID}
          open={isOpenUploadModal}
          keepMounted
        >
          <Title id={UPLOAD_MODAL_ID} onClose={handleClose}>
            Upload
          </Title>
          <Content className={classes.uploadDialogContent}>{uploadComponent}</Content>
        </Dialog>
        {allowFileOpen && (
          <Dialog
            classes={{
              paper: classes.dialog,
            }}
            fullWidth
            onClose={handlePreviewClose}
            aria-labelledby={previewModalId}
            open={preview.isOpen}
          >
            <Title id={previewModalId} onClose={handlePreviewClose}>
              {preview.fileName}
            </Title>

            <Content className={classes.dialogContent}>
              {PreviewComponent && (
                <PreviewComponent
                  path={path}
                  filePath={preview.filePath}
                  fileName={preview.fileName}
                  body={beforeSendBody}
                />
              )}
            </Content>
          </Dialog>
        )}
      </>
    );
  },
);

export default memo(FileManager);
