import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Button, Column } from 'devextreme-react/data-grid';
import Swiper from 'modules/shared/components/Swiper';
import { useToggle } from 'modules/shared/hooks/base';

import EditingWrapper from 'modules/shared/components/Grid/wrappers/EditingWrapper';
import ToolbarWrapper from 'modules/shared/components/Grid/wrappers/ToolbarWrapper';
import Grid from 'modules/shared/components/Grid';

import { DataStoresState } from '../context/DataStoresState';

import { useLoadSyncPathes } from '../hooks/useLoadSyncPathes';
import { useSyncPathActions } from '../hooks/useSyncPathActions';
import FileExplorer from './FileExplorer';
import SyncPathSchedulerModal from '../sync-path-scheduler-modal-ui';
import { SyncPathInput } from '../types';
import { DataStoreType } from '../contants';

const DataGrid = ToolbarWrapper(EditingWrapper(Grid));

const FileManagerUi = ({ renderButtons, readOnly }) => {
  const { connection, projectId, dataStoreConnection, dataStoreConnections } = useContext(DataStoresState);

  const { pathes } = useLoadSyncPathes({ connection, dataStoreConnection, projectId, type: 'import' });
  const { remove, create, creating, updateScheduler, updatingScheduler } = useSyncPathActions({
    connection,
    projectId,
    type: 'import',
  });

  const onDeleteRow = useCallback(
    (pathId) => {
      remove(pathId);
    },
    [remove],
  );

  const p = useMemo(() => JSON.parse(JSON.stringify(pathes)), [pathes]);

  const [isSchedulerModalOpen, { deactivate: close, activate: open }] = useToggle(false);
  const [editingPathId, setEditingPathId] = useState('');
  const tempPathes = useRef<SyncPathInput[]>([]);

  useEffect(() => {
    if (!isSchedulerModalOpen) {
      tempPathes.current = [];
    }
  }, [isSchedulerModalOpen]);

  const initialValues = useMemo(() => {
    if (editingPathId) {
      const path = pathes.find((cur) => cur.pathId === editingPathId);

      return {
        name: path?.name,
        filter: path?.filter,
        unzip: path?.unzip,
        loadDeltaLake: path?.loadDeltaLake,
        scheduler: path?.scheduler.type || '',
        schedulerValue: path?.scheduler.pattern.split(' ') || null,
        schedulerLabel: path?.scheduler.label || '',
      };
    }

    const name = tempPathes?.current?.[0]?.externalPath ?? '';
    return {
      name,
      filter: '',
      unzip: false,
      loadDeltaLake: true,
      scheduler: '',
      schedulerValue: '',
      schedulerLabel: '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingPathId, pathes, isSchedulerModalOpen]);
  const closeModal = useCallback(() => {
    setEditingPathId('');
    close();
  }, [close]);

  const c = useMemo(
    () => dataStoreConnections.find((it) => it.connectionId === dataStoreConnection),
    [dataStoreConnections, dataStoreConnection],
  );

  if (!dataStoreConnection) {
    return null;
  }

  const fileManagerComponent = (
    <>
      <FileExplorer
        connection={connection}
        projectId={projectId}
        dataStoreConnection={dataStoreConnection}
        creating={creating}
        onCreate={(data) => {
          return create(
            () => null,
            dataStoreConnection,
            data.name,
            { filter: data.filter, unzip: data.unzip, loadDeltaLake: data.loadDeltaLake },
            data.pathes,
            data.scheduler,
          );
        }}
      />
    </>
  );

  const table = (
    <DataGrid
      key={`${connection}-${projectId}`}
      dataSource={p}
      gridOptions={{
        editing: {
          allowUpdating: !readOnly,
          allowDeleting: !readOnly,
        },
        height: '100%',
      }}
      columns={[
        {
          dataField: 'name',
          caption: 'Name',
          minWidth: 120,
        },
        {
          dataField: 'path',
          caption: 'Path',
          minWidth: 120,
        },
        {
          dataField: 'hdfsLocation',
          caption: 'Storage Path',
        },
        {
          dataField: 'scheduler.label',
          caption: 'Scheduler',
          alignment: 'center',
          minWidth: 120,
        },
        { dataField: 'isFile', caption: 'File', width: 100, alignment: 'center' },
        { dataField: 'unzip', caption: 'Unzip', width: 100, alignment: 'center' },
        { dataField: 'loadDeltaLake', caption: 'Load Delta Lake', width: 100, alignment: 'center' },
        { dataField: 'filter', caption: 'Filter', width: 100, alignment: 'center' },
        ...(!readOnly
          ? [
              {
                component: (
                  <Column key="buttons" type="buttons">
                    <Button
                      name="edit"
                      onClick={(args) => {
                        setEditingPathId(args.row.data.pathId);
                        open();
                      }}
                    />
                    <Button
                      name="delete"
                      onClick={(args) => {
                        onDeleteRow(args.row.data.pathId);
                      }}
                    />
                  </Column>
                ),
              },
            ]
          : []),
      ]}
    />
  );

  return (
    <>
      <Swiper
        renderButtons={renderButtons}
        items={[
          {
            title: 'File Manager',
            component: fileManagerComponent,
          },
          {
            title: 'Uploads',
            component: table,
          },
        ]}
      />
      <SyncPathSchedulerModal
        isOpen={isSchedulerModalOpen}
        close={closeModal}
        customFields={{ unzip: true, filter: c?.type === DataStoreType.Sharepoint, loadDeltaLake: true }}
        initialValues={initialValues}
        executing={creating || updatingScheduler}
        onSubmit={(name, custom, scheduler) => {
          return updateScheduler(closeModal, dataStoreConnection, editingPathId, name, custom, scheduler);
        }}
      />
    </>
  );
};

export default memo(FileManagerUi);
