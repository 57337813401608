import { AccountInfo, AuthError, EventType, PublicClientApplication } from '@azure/msal-browser';
import { authThunks } from 'modules/auth';
import Storage from 'modules/tokens-storage';
import { showGlobalSnackbar } from 'main/globals';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { ssoLogin, resetPassword, ssoUserFriendlyError } from 'modules/auth/thunks/ssoLogin';
import { history } from 'configure/browserHistory';
import { msalConfig } from './config';
import { store } from '../../index';

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS
  ) {
    if ((event.payload as AccountInfo)?.localAccountId) {
      msalInstance.setActiveAccount(event.payload as AccountInfo);
      // @ts-ignore
    } else if (event.payload?.account) {
      // @ts-ignore
      msalInstance.setActiveAccount(event.payload.account);
    }
    // @ts-ignore
    store.dispatch(ssoLogin());
  }

  if (event.eventType === EventType.HANDLE_REDIRECT_END) {
    const account = msalInstance.getActiveAccount();
    const isExpired = window.location.hash.includes('AADB2C90208');
    const isTokenInHash = window.location.hash.includes('id_token');
    const isSsoPath = window.location.pathname.includes('sso');

    if (!account && isTokenInHash && isSsoPath) {
      if (isExpired) {
        history.replace('');
      } else {
        msalInstance.loginRedirect();
      }
    }
  }
});
msalInstance.initialize().then(() => {
  msalInstance.handleRedirectPromise().catch((err) => {
    if (err instanceof AuthError) {
      if (err.errorMessage.startsWith('AADB2C90118')) {
        // User press forget password
        // @ts-ignore
        store.dispatch(resetPassword());
      } else if (err.errorMessage.startsWith('AADB2C90091')) {
        // user cancel password reset
        // @ts-ignore
        store.dispatch(ssoUserFriendlyError());
      } else if (err.errorMessage.startsWith('AADB2C90157')) {
        // User has exceeded the maximum number for retries for a self-asserted step
        // @ts-ignore
        store.dispatch(ssoUserFriendlyError('Maximum retries exceeded'));
      }
    }
  });
});

const EACH_HOUR = 1000 * 60 * 60;
let sessionCheckTimeout: number | undefined;

export const checkCurrentSession = async (dispatch, skipLogout = false): Promise<void> => {
  clearTimeout(sessionCheckTimeout);

  try {
    const account = msalInstance.getActiveAccount();

    if (!account) {
      throw new Error('No active account');
    }
    const token = await msalInstance.acquireTokenSilent({
      account,
      scopes: ['openid', 'profile', 'email'],
    });

    Storage.setIdToken(token.idToken);

    sessionCheckTimeout = setTimeout(() => {
      checkCurrentSession(dispatch);
    }, EACH_HOUR) as unknown as number;
  } catch (e) {
    if (!skipLogout) {
      dispatch(authThunks.logout());
    }
    showGlobalSnackbar('SSO session expired', BASE_ERROR_NOTIFICATION_OPTIONS);

    throw e;
  }
};

export default msalInstance;
